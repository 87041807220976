import React from "react";
import Accordion from "../utils/Accordion";

const faqs = [
  {
    question: "What's the difference between verified and not-verified emails?",
    answer:
      "Verified emails are emails that we have validated with the server directly. We only charge for verified emails.Some servers do not allow for verification and when that happens and we can't verify the email we can only provide our best guess, for that reason we do not charge for unverified emails.",
  },
  {
    question: "How do you find the emails?",
    answer:
      "To find emails we use a variety of different methods including searching for them on websites, sourcing them from external services, or guessing the pattern. Once we have an email we then validate each email with the email server.",
  },
  {
    question:
      "I have a file with 1,000 rows, how much will it cost to find emails?",
    answer: "For every verified email we find you will be charged 1 credit.",
  },
  {
    question: "How do the credits work?",
    answer: "We ONLY charge a credit for verified emails.",
  },
  {
    question: "Can I upgrade / downgrade or cancel?",
    answer:
      "You can upgrade or downgrade your plan at any time. Changing plans will shift your billing period so you can start using credits right away! If you cancel, you'll be able to continue using your credits until the end of your billing period.",
  },
  {
    question: "What columns do I need in the file I want to upload?",
    answer:
      "Once you upload the file, depending on the option you choose, it will give you instructions. Ideally you should have the prospect’s name and website but if you have no name we can find all the emails for each domain in your list.",
  },
  {
    question: "How long do CSV files take to process?",
    answer: "5-10 minutes, max.",
  },
  {
    question: "What is a “verified email”?",
    answer:
      "Verified emails are emails that we have validated with the server directly. We only charge for verified emails.",
  },
  {
    question: "How do rollovers work?",
    answer:
      "If you don't use your credits one particular month, you'll be able to use them for as long as you don't downgrade or unsubscribe.",
  },
  {
    question: "How do I cancel my subscription?",
    answer:
      "You can cancel your subscription by going to your account settings, under billing.",
  },
];

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">
              Here are the questions we are asked most often and their answers.
            </h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            {faqs.map((faq, index) => (
              <Accordion key={index} title={faq.question}>
                {faq.answer}
              </Accordion>
            ))}
            <span
              className="block border-t border-gray-200"
              aria-hidden="true"
            ></span>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
